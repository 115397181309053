import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from '../environments/environment';
import { register } from 'swiper/element/bundle';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    constructor(private platform: Platform) {
        register();

        /**
         * This is a workaround to set the API URL from the current hostname, because the hostname is determined during
         * compile for the emulator
         */
        this.platform
            .ready()
            .then(() => {})
            .catch((error) => console.log(JSON.stringify(error)));
    }
}
